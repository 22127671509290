// @ts-nocheck

import SmileOutlined from '@ant-design/icons/SmileOutlined';
import UserOutlined from '@ant-design/icons/UserOutlined';
import DeploymentUnitOutlined from '@ant-design/icons/DeploymentUnitOutlined'

export default {
  SmileOutlined,
UserOutlined,
DeploymentUnitOutlined
}
    